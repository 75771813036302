/* .card{
    min-height: 300px;
} */

.weekcalendarcontainer {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  margin-bottom: 3rem;
}
.dayweekcontainer {
  max-width: 200px;
}
.dayweeklabel {
  min-width: 170px;
}
.dayweeklabel h5 {
  color: #f5eeee !important;
}
.week-card-body-title > h5 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.week-card-body-title {
  padding: 0.125rem;
}
.week-card-container:hover {
  background-color: #ed9007;
  color: rgb(255, 255, 255) !important;
}
.dayweekcard {
  display: block;
  overflow-x: hidden;
}
.weekcalendarcontainer {
  flex-direction: row;
}
.dayweeklabelcard {
  padding: 1rem 0;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 750px) {
  .weekcalendarcontainer {
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
  .week-card-body-title > h5 {
    font-size: 1.25rem;
  }
  .dayweekcontainer {
    max-width: fit-content;
  }
  .dayweeklabelcard {
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 24px);
    margin-bottom: 0% !important;
  }
  .week-card-container {
    width: calc(100vw - 64px);
  }

  .card-event-body-title h5 {
    font-size: 1.25rem;
  }
  .dayweekcard {
    display: flex;
    overflow-x: scroll;
    padding: 0px 12px;
  }
}
@media only screen and (min-width: 750px) {
  /* width */

  .weekcalendarcontainer::-webkit-scrollbar {
    height: 10px;
  }
  /* Track */
  .weekcalendarcontainer::-webkit-scrollbar-track {
    background-color: azure;
    border-radius: 10px;
  }

  /* Handle */
  .weekcalendarcontainer::-webkit-scrollbar-thumb {
    background: rgb(160, 155, 155);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a38686;
  }
}
@media only screen and (max-width: 1200px) {
  .weekcalendarcontainer {
    justify-content: initial;
  }
}
