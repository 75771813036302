.error-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
}

.title-error {
    margin-bottom: 10px;
    color: rgb(232, 67, 67);
    padding-top: 20px;
}

.text-error {
    margin: 0;
    color: rgba(232, 67, 67, 0.645);
    font-size: x-large;
}

.url-error {
    width: 100%;
}

.img-error {
    width: 50%;
    margin-top: 10px;
}

.section-svg-error {
    width: 40%;
}

@media screen and (max-width: 414px) {
    .section-svg-error {
        width: 80%;
    }
}