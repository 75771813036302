.envolveformsheduleevents{
    background-color: #f5eeee;
}
.alignlabelmid{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.gapcss{
    gap: 15px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.minwidthbutton{
    min-width: 90px;
}
.maxwithformevents{
    max-width: 960px;
}
.paddchild *{
    margin: 0 0.5rem;
}