/*LANDING PRINCIPAL*/
.landing-clase-reunionDeSocios {
  background: url("./resources/LandingPageFondo.png") no-repeat;
}

@media screen and (max-width: 768px) {
  .landing-clase-reunionDeSocios {
    background: url("./resources/LandingPageFondoMobile.png") no-repeat;
    background-position: bottom;
    background-size: cover;
  }
}
