.btncodeval{
    width: 100%;
}
.form-label{
    font-size: 14px;
}
label{
    font-size: 14px;
}


