.payment-main{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.payment-main .kpedido{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.payment-main .kpedido .info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.payment-main .kpedido .info p{
    display: flex;
}
.payment-main .kpedido .info span{
    display: flex;
}
.payment-main .kpedido .info span p:first-child{
    /* font-weight: bold; */
    text-decoration: line-through;
    margin-right: 2px;
    /* gis */
    font-size: 12px;
    color: #ccc;
}
.payment-main .kpedido .total{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 600;
}

/* efectivo */
.payment-main .kpefec{
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.payment-main .kpefec .checkefe{
    display: flex;
    align-items: center;
}
.payment-main .kpefec .checkefe-cash{
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}
.payment-main .kpefec .checkefe-cash p{
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0;
}
.payment-main .kpefec .checkefe-cash p:nth-child(1){
    color: red;
}
.payment-main .kpefec .checkefe-cash p span{
    background-color: rgb(225, 223, 160);
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}
/* count */
.efectpay{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

}
.efectpay button{
    background-color: #ed9007;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
}
/* payment */
.confirmPayment{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.confirmPayment button{
    background-color: #ed9007;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
    width: 100%;
}
.confirmPayment button:last-child{
    background-color: #ccc;
    color: #000;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
    width: 100%;
}