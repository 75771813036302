@media (min-width: 992px){
    .lg8 {
        flex: 0 0 auto;
        width: 60%;
    }
    .lg4{
        flex: 0 0 auto;
        width: 40%;
    }
}
.txtredpromcode{
    color: red;
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
    text-align: center;
}
.txtgreenpromcode{
    color: rgb(47, 218, 47);
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
    text-align: center;
}
.form-control-style{
    height: 100%;
    font-size: 14px;
}