.korderlist-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.korderlist-content .card-order {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* margin: 10px;
    padding: 10px; */
}

.korderlist-content .card-order .header-status {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
}
.korderlist-content .card-order .header-status .status {
  background-color: #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  display: flex;
  width: 100%;
}
.korderlist-content .card-order .header-status .status.cancel {
  background: #f00;
color: #fff;
}
.korderlist-content .card-order .header-status .status.pending {
  background: #f0ad4e;
  color: #fff;
}
.korderlist-content .card-order .header-status .status.recived{
    background: #5cb85c;
    color: #fff;
}
.korderlist-content .card-order .body-order {
  display: flex;
  padding: 10px;
  margin: 10px;
  align-items: center;
}
.korderlist-content .card-order .body-order .order-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.korderlist-content .card-order .body-order .order-info .store {
  display: flex;
  align-items: center;
  width: 100%;
}
.korderlist-content .card-order .body-order .order-info img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.korderlist-content .card-order .body-order .order-info p {
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
  display: flex;
    flex-direction: column;

}
.korderlist-content .card-order .body-order .order-info p span{
    font-size: 10px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left;
}
.korderlist-content .card-order .body-order .order-info .btn-details {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.korderlist-content .card-order .body-order .order-info .btn-details a {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  display: flex;
  text-decoration: none;
  color: #000;
}
.korderlist-content .card-order .body-order .order-info .btn-details a:hover {
  background-color: #ccc;
  color: #fff;
}
