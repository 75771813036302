.card-product-keola {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid #ebebeb;
  width: 200px;
}
.card-product-keola .stock-none{
    background-color: #f5f5f5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
    z-index: 1;
    opacity: 0.8;
}
@media screen and (max-width: 581px) {
  .card-product-keola {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .card-product-keola {
    width: 150px;
  }
}
.card-product-keola .keola-image {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.card-product-keola .keola-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.card-product-keola .keola-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}
.card-product-keola .keola-content .name {
  font-size: 16px;
  font-weight: 600;
  color: #666;
  margin-bottom: 5px;
}
.card-product-keola .keola-content .description {
  font-size: 12px;
  color: #999;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap ;

}
.card-product-keola .keola-content .price {
  font-size: 12px;
  color: #666;
  display: flex;
  flex-direction: row;
}
/* subrayado */
.card-product-keola .keola-content .price p:first-child {
  margin-right: 5px;
  font-size: 12px;
  color: #666;
  font-weight: 300;
  text-decoration: line-through;
}
.card-product-keola .keola-content .price p {
  font-size: 12px;
  color: #666;
  font-weight: 600;
}
/* add cart */
.card-product-keola .addcart {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-product-keola .addcart button {
  border: none;
  outline: none;
  background-color: rgb(103, 223, 103);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.card-product-keola .addcart button.min {
  background-color: rgb(217, 86, 86);
}
