.orgchart {
  background: #ffffff !important;
}

.orgchart-container {
  border: 0px;
}

.orgchart ul li .oc-node .oc-heading {
  background-color: #142d47;
}

.orgchart ul li .oc-node .oc-content {
  border: 1px solid #142d47;
}

.orgchart > ul > li > ul li::before {
  border-top: 2px solid #142d47;
}

.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: #142d47;
}
.orgchart > ul > li > ul li > .oc-node::before {
  background-color: #142d47;
}

.orgchart .root1 .content {
  border-color: #cc0066;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.nav-link {
  font-size: 14px;
  font-weight: bolder;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #f2931d;
}

.navbar-nav .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: bolder;
  color: #7c7c7d;
}

.navbar-nav .dropdown-menu .dropdown-item:hover {
  color: #f2931d;
} /*
@media (min-width: 768px) {
    .chat-externo{
        background-color: red !important;
    }
}*/
