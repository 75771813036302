#txtQuotes{
    width: auto;
    display: inline;
}
.sizetext{
    font-size: 14px
}
.sizetext>small{
    font-size: 14px;
}
.sizetextmargincero{
    font-size: 14px;
    margin: 0px,
}
.sizecheckpcontado{
    font-size: 14px
}
.aligntextpayment{
    text-align: right;
}
.csspaymentmethod{
    width: 100%;
    max-width: 136px;
    cursor:pointer
}

.paddediconpaymentmethod{
    padding: 16px;
}
.aligns{
    display: flex;
    align-items: center;
}
