.s {
    background-color: transparent;
}
.styleModalLoading {
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    border-width: 0px;

}
.styleModalBackdrop{
    background-color: rgba(0,0,0,0.13)
}
.alignspinner{
    margin: auto;
}