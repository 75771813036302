.App {
  text-align: center;
  font-size: 12px;
  color: #142d47;
}

.App .form-control {
  font-size: 12px;
}

.dropdown {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.dropdown .form-check {
  font-size: 12px;
  font-weight: normal;
}

.dropdown span {
  font-size: 12px;
}

.gMCkKA {
  height: 590px !important;
}
.kMLIPw {
  top: 95px !important;
}
