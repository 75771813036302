.card-event-body-title>p,a{
    font-size: 1rem;
}
.event-card-img{
    width: 100%;
}
.cursor-pointer{
    cursor: pointer;
}
.event-card-container:hover{
    background-color: #ed9007;
    color: rgb(255, 255, 255)!important;
}

.style-body-card-event{
    border-top: solid 1px rgba(0,0,0,0.125);
}
.style-body-card-event *{
    font-size: 0.8rem;
}
