.shopkeola-main{
    display: flex;
    flex-direction: column;
    background-color: #fff;
}


.shopkeola-main .cart-main .cartItems{
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    margin: 20px 20px;
}
@media (max-width: 768px) {
    .shopkeola-main .cart-main .cartItems{
        padding: 0;
        margin: 20px 10px;
    }
}