.radiobutton-month .btn-check:active+.btn-outline-primary,
.radiobutton-month .btn-check:checked+.btn-outline-primary,
.radiobutton-month .btn-outline-primary.active,
.radiobutton-month .btn-outline-primary.dropdown-toggle.show,
.radiobutton-month .btn-outline-primary:active{
    background-color: #fda10d;
    border-color: #fda10d;
}
.radiobutton-month .btn-outline-primary{
    background-color: #ffffff;
    color: #fda10d;
    border-color: #fda10d;
}
.radiobutton-month{
    padding: 0px 0.25rem;
}
@media only screen and (max-width: 1100px) {
    .radiobutton-month{
        padding: 0.25rem;
    }
}
.radiobutton-month .btn-outline-primary:focus,.radiobutton-month .btn-check:focus+.btn-outline-primary{
    box-shadow: 0 0 0 0.25rem #ffc10736;
}
.btn-group-select-year button{
    margin: 0px 0.25rem;
}
.btn-group-select-year button h5{
    margin-bottom: 0%;
}
.btn-group-select-year .buttonselectyear{
    background-color: #ffffff;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s
}
.btn-group-select-year .buttonselectyear h5{
    color: #0d6efd;
}
.btn-group-select-year .buttonselectyear:focus{
    box-shadow: 0 0 0 0.25rem #ececf180;
}
.spinner-calendar-year{
    position: fixed;
    bottom: calc(25% - 2rem);
    right: calc(50% - 2rem);
    width: 4rem;
    height: 4rem;
}
.spinner-calendar-year{
    position: fixed;
    bottom: calc(50% - 2rem);
    right: calc(50% - 2rem);
    width: 4rem;
    height: 4rem;
}