.cartItems .kcart-item{
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
}
.cartItems .kcart-item .img{
    width: 150px;
    height: 150px;
    border-radius: 30px;
    overflow: hidden;
}
@media (max-width: 768px) {
    .cartItems .kcart-item .img{
        width: 100px;
        height: 100px;
    }
}
.cartItems .kcart-item .img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.cartItems .kcart-item .info{
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-between;
    /* padding: 0 20px; */
}
.cartItems .kcart-item .info .name{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
}
.cartItems .kcart-item .info .name p{
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0px 0px 1px #ccc;
}
@media (max-width: 768px) {
    .cartItems .kcart-item .info .name p{
        font-size: 14px;
    }
}
.cartItems .kcart-item .info .name p span{
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: 0px 0px 1px #ccc;
}
.cartItems .kcart-item .info .price{
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    width: 100%;
}
.cartItems .kcart-item .info .quantity{
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    flex-direction: column;
    /* padding: 5px 10px; */
    /* border-radius: 1rem; */
    width: 100%;
    align-items: center;
    justify-content: center;
}
.cartItems .kcart-item .info .quantity .addcart{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cartItems .kcart-item .info .quantity .addcart button{
    border: none;
    outline: none;
    background-color: rgb(103, 223, 103);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.cartItems .kcart-item .info .quantity .addcart button.min{
    background-color: rgb(217, 86, 86);
}