.btn-fixed-keola{
    position: fixed;
    margin: 0;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
}
.btn-fixed-keola a{
    width: 100%;
    height: 40px;
    background-color: #ed9007;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
}