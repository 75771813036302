.rbc-header{
    background-color: #f5eeee 
    /*rgba(49, 119, 159, 0.623)!important;*/
}
.rbc-today{
    background-color: #f5bb65!important
}
.rbc-toolbar{   
    background-color: #f5eeee;
    margin-bottom: 0px!important;
    padding-bottom: 10px;
    margin-top: 20px;
}
.rbc-time-view, .rbc-month-view{
    background-color: #f5eeee;
}

.rbc-time-header{
    margin-right: 16px!important;
}
@media only screen and (max-width: 1100px){
    .rbc-time-header{
        margin-right: 0px!important;
    }
}
.alignpopovercontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.spinner-calendar-month{
    position: fixed;
    top: calc(50% - 2rem);
    right: calc(50% - 2rem);
    width: 4rem;
    height: 4rem;
}