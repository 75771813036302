@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

/*body      {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}*/

#root {
  width: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

.App,
.auth-wrapper {
  height: 100%;
}

.layout-general {
  width: 100%;
  height: 100%;
  background: url("./images/home/banner1.jpg") no-repeat;
  background-size: cover;
}





.form-check-input {
  width: 1.25em !important;
  height: 1.25em !important;
  margin-top: 0.2em !important;
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  max-width: 450px;
  min-width: 320px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 20px;
  border-radius: 15px;
  transition: all 0.3s;
}
.auth-inner-autoregister {
  min-width: 350px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 15px;
}

.auth-inner-autoregister.req-new-j{
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.auth-inner-autoregister.req-new-j .formautoregistercontainer{
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 15px;
  padding: 15px;
}

.container-btn-search-partners button{
  width: 100%;
  height: 45px;
  font-size: 15px;
  border: none;
  background-color: #fff;
}

.container-btn-search-partners{
  padding: 0 20px;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.icon-login {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnreestablecer {
  width: 8rem !important;
  min-width: 8rem !important;
}

.formloggincontainer {
  min-width: 380px;
  /*padding: 5px;*/
  margin: 0 auto;
}

.formloggincontainerVolver{
 margin-bottom: 50px;
 text-align: left;
 min-width: 380px;
} 

@media(max-width: 480px){
  .formloggincontainer{
    width: 100%;
    min-width: 100%;
    margin: 0;
  }
  .formloggincontainerVolver{
    width: 100%;
    min-width: 100%;
  }
}

.formautoregistercontainer {
  width: 320px;
  padding: 5px;
  margin: 20px auto;
}
.formrecoverpasswordcontainer {
  width: 320px;
  padding: 10px;
  margin: auto;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
  font-size: 14px;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.textAlert {
  color: #ff0000;
}

.register-inner {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px 30px;
  border-radius: 15px;
  transition: all 0.3s;
  text-align: left;
  margin-bottom: 100px;
}

.register-form {
  background: white;
  margin: auto;
  padding: 40px 20px;
  font-size: 12px;
  margin: 20px;
}

.home-container {
  background: white;
  margin: auto;
  padding: 20px 20px 100px;
  font-size: 12px;
}

.panel-form {
  text-align: left;
  background: white;
  width: 95%;
  max-width: 700px;
  padding: 35px;
  margin: auto;
  margin-top: 10px;
}
.panel-form h4 {
  text-align: center;
  border-bottom: 2px solid #e4e4e4;
  border-left: 6px solid #f2931d;
}

.form-style {
  background: white;
  padding: 35px;
  margin: 10px;
}

.form-style h4 {
  text-align: center;
  border-bottom: 2px solid #e4e4e4;
  border-left: 6px solid #f2931d;
}

.menu-content {
  padding: 5px;
}
.menu-item a {
  cursor: pointer;
}

.menu-content .menu-item {
  margin: 0;
}

.col-image {
  width: 40px;
  margin-bottom: 10px;
}

.menu-item svg:hover {
  fill: #f0692c;
}
.menu-item p {
  font-weight: bold;
}

.modal-custom {
  font-size: 14px;
}
.modal-custom .form-text {
  font-size: 14px;
  text-align: center;
}
.modal-custom .form-label {
  margin-bottom: 0px;
}

.tableCustom th {
  background-color: #007bff;
  color: white;
}

.card-header-custom {
  background: #1b84e9;
  font-weight: bold;
  color: #1b84e9;
  text-transform: uppercase;
}
.textMessage {
  color: #1570c2;
}

hr.line {
  background-color: #f99e2d;
  height: 1px;
}

.box-container-active {
  border: 2px solid #f0692c;
  background: #f0692c;
  border-radius: 5px;
  min-width: 100px;
  /* width: 200px; */
  margin: 5px 5px 30px;
  padding: 30px;
  position: relative;
  height: 130px;
}

.box-container-active .class-state {
  color: #fff;
  font-size: 12px;
  margin-top: 2px;
}

.box-container-active svg {
  fill: #142d47;
  margin: 10px;
}

.box-icon {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
}

.box-container {
  border: 2px solid #f0692c;
  border-radius: 5px;
  min-width: 100px;
  margin: 5px;
  padding: 10px;
  margin-top: 20px;
  position: relative;
  /* display: inline-flex; */
}

.box-icon-state {
  width: 40px;
  height: 40px;
  padding-left: 40px;
}

.box-container img {
  width: 30px;
}

.box-container:hover {
  border: 2px solid #f0692c;
}
.title-state {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1px;
}
.subtitle-state {
  font-size: 10px;
  letter-spacing: -0.6px;
}

.box-title {
  color: #f0692c;
  font-weight: bold;
  margin-bottom: 1px;
  margin-top: -7px;
}
/* .box-container div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.box-container-range {
  background: #eee;
  border: 0px;
  border-radius: 5px;
  /* min-width: 100px; */
  margin: 5px 1px;
  padding: 30px 5px 30px 5px;
  position: relative;
  height: 120px;
  /* display: inline-flex; */
}

.class-state {
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.class-progress {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 1px;
}

progress {
  display: block; /* default: inline-block */
  width: 30px;
  margin: 3px auto;
  padding: 0px;
  border: 0 none;
  background: #c3c5c4;
  /* box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.2); */
}
progress::-moz-progress-bar {
  background: #f0692c;
  /* box-shadow: inset 0 -2px 4px rgba(0,0,0,0.4), 0 2px 5px 0px rgba(0,0,0,0.3); */
}
/* webkit */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  progress {
    height: 4px;
  }
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  border-radius: 12px;
  background: #142d47;
  /* box-shadow: inset 0 -2px 4px rgba(0,0,0,0.4), 0 2px 5px 0px rgba(0,0,0,0.3);  */
}

.range-title {
  font-size: 12px;
}

.content-footer {
  color: #142d47;
  font-size: 14px;
}

.range-img {
  border: 3px solid #3897c3;
  border-radius: 5px;
  margin: 5px auto 30px;
  width: 140px;
  height: 120px;
}
.range-img img {
  width: 100px;
}
.spinnerinicio {
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  vertical-align: middle;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s ease infinite spinner-border;
}

.content-title {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
}
.content-subtitle {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 15px;
  border-bottom: 2px solid #e5e5e5;
  font-weight: bold;
  text-transform: uppercase;
}
.label-title {
  color: #142d47;
  font-size: 14px;
  font-weight: bold;
}

.box-container-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.box-suscription {
  color: #142d47;
  background: #eeeeee;
  border: 2px solid #f0692c;
  border-radius: 5px;
  max-width: 200px;
  min-width: 150px;
  margin: 20px;
  padding: 30px 5px 30px 5px;
  position: relative;
  height: 120px;
  font-weight: bold;
}

.box-suscription:hover {
  cursor: pointer;
}

.table-head {
  background: #e6ebe0;
}
.table-head-child {
  background: #e6ebe0;
  border-left: 3px solid;
  font-size: 10px;
}
.table-head-comision {
  background: #5b6884;
  color: #fff;
}

.table-schedule {
  display: block;
  overflow-x: "auto";
}

.table-schedule td,
.table th {
  padding: 0.3rem;
}

/* .table-headsch {
  background: #e6ebe0;
  border-left: 3px solid #142d47;

} */
.table-responsive,
.table-responsive-md {
  overflow-x: auto;
  text-align: center;
  background-color: #fcfcfc;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.table {
  font-size: 14px;
  margin-bottom: 0px !important;
}
/* Accordion */
.accordion > .card > .card-header {
  background: #eeee;
  border-left: 3px solid #f0692c;
  padding: 2px;
}

.title-wallet {
  color: #f0692c;
  font-size: 14px;
  font-weight: bold;
  margin: 1rem 1px 1px;
}

.card-wallet {
  border: 2px solid #147de4;
}

.text-problem {
  font-weight: bold;
  color: #d62323;
  text-align: right;
  padding: 1px 10px 10p;
  margin: 5px;
}

.text-problem:hover {
  cursor: pointer;
}

.box-legend {
  text-align: left;
  font-size: 10px;
  padding-left: 10px;
}
.box-legend .form-label {
  margin: 1px 5px;
}

.box-legend svg {
  vertical-align: unset;
}

.card-suscription {
  width: 150px; /* Set width of cards */
  display: flex; /* Children use Flexbox */
  flex-direction: column; /* Rotate Axis */
  border: 2px solid #92d04f; /* Set up Border */
  border-radius: 4px; /* Slightly Curve edges */
  overflow: hidden; /* Fixes the corners */
  margin: 10px; /* Add space between cards */
}
.card-suscription:hover {
  cursor: pointer;
}
.card-header-suscription {
  background: #92d04f;
  color: #142d47;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  min-height: 46px;
}

/* overdue */
.card-suscription-overdue {
  width: 150px; /* Set width of cards */
  display: flex; /* Children use Flexbox */
  flex-direction: column; /* Rotate Axis */
  border: 2px solid #ffc000; /* Set up Border */
  border-radius: 4px; /* Slightly Curve edges */
  overflow: hidden; /* Fixes the corners */
  margin: 10px; /* Add space between cards */
}
.card-suscription-overdue:hover {
  cursor: pointer;
}
.card-header-suscription-overdue {
  background: #ffc000;
  color: #142d47;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  min-height: 46px;
}

/* Default */
.card-comission {
  width: 200px; /* Set width of cards */
  display: flex; /* Children use Flexbox */
  flex-direction: column; /* Rotate Axis */
  border: 2px solid #eee; /* Set up Border */
  border-radius: 4px; /* Slightly Curve edges */
  overflow: hidden; /* Fixes the corners */
  margin: 10px; /* Add space between cards */
  background: #eee;
  text-align: center;
}

/* Default */
.card-suscription-default {
  width: 150px; /* Set width of cards */
  display: flex; /* Children use Flexbox */
  flex-direction: column; /* Rotate Axis */
  border: 2px solid #f3966d; /* Set up Border */
  border-radius: 4px; /* Slightly Curve edges */
  overflow: hidden; /* Fixes the corners */
  margin: 10px; /* Add space between cards */
}
.card-suscription-default:hover {
  cursor: pointer;
}
.card-header-suscription-default {
  background: #f3966d;
  color: #142d47;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  min-height: 46px;
}

/* inactive */

.card-suscription-inactive {
  width: 150px; /* Set width of cards */
  display: flex; /* Children use Flexbox */
  flex-direction: column; /* Rotate Axis */
  border: 2px solid #bbbaba; /* Set up Border */
  border-radius: 4px; /* Slightly Curve edges */
  overflow: hidden; /* Fixes the corners */
  margin: 10px; /* Add space between cards */
}
.card-header-suscription-inactive {
  background: #bbbaba;
  color: #142d47;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  min-height: 46px;
}
.card-main-suscription {
  display: flex; /* Children use Flexbox */
  flex-direction: column; /* Rotate Axis to Vertical */
  /* justify-content: center;    Group Children in Center */
  align-items: center; /* Group Children in Center (on cross axis) */
  padding: 15px 0; /* Add padding to the top/bottom */
}

.main-suscription {
  color: #142d47;
  font-size: 12px;
  text-align: center;
}

.search {
  border: 2px solid #7b7b7b;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
}

.search-title {
  font-size: 12px;
  width: 80px;
  margin-top: -16px;
  margin-left: 10px;
  background: white;
  text-align: center;
}

.div-disabled {
  pointer-events: none;
  opacity: 0.4;
}

/* Default  */
.card-range {
  width: 150px;
  display: flex;
  flex-direction: column;
  background: #dee2e6;
  border-radius: 4px;
  overflow: hidden;
  margin: 20px;
  color: #142d47;
}

.description {
  color: #29598c;
}

.content-comission {
  text-align: left;
  padding-top: 20px;
}

/* Para 960px */
@media only screen and (max-width: 980px) and (min-width: 821px) {
  .content-comission {
    width: 460px;
  }
}

/* Para 800px */
@media only screen and (max-width: 820px) and (min-width: 621px) {
  .content-comission {
    width: 560px;
  }
}

/* Para 600px */
@media only screen and (max-width: 620px) and (min-width: 501px) {
  .content-comission {
    width: 560px;
  }
}

/* Para 480px */
@media only screen and (max-width: 500px) and (min-width: 341px) {
  .content-comission {
    width: 250px;
  }
}
.form-style-1 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.container-logo {
  width: 4rem;
  margin: auto auto 0.8rem auto;
  border-radius: 50px;
  padding: 0.6rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
