.delivery-main{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.delivery-main .deliveryItems{
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    margin: 10px 10px;
}
@media (max-width: 768px){
    .delivery-main .deliveryItems{
        padding: 0 10px;
    }
}
.delivery-main .deliveryItems .deliveryItem{
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ed9007;
    border: 1px solid #ed9007;
    cursor: pointer;
}
.delivery-main .deliveryItems .deliveryItem:hover{
    box-shadow: 0px 0px 10px #ed9007;
    background-color: rgba(244, 228, 205, 0.265);
}
.delivery-main .deliveryItems .deliveryItem img{
    width: 150px;
    height: 150px;
    overflow: hidden;
    object-fit: contain;
}
.delivery-main .deliveryItems .deliveryItem h3{
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    width: 100%;
    text-align: center;
}
.klistOptions{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.klistOptions div{
    background-color: #fd8e00;
    color: #fff;
    font-size: 15px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
}