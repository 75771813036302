.imgcarouselevent{
    display: block;
    margin: auto;
    max-width: 900px;
    width: 100%;
}
.datetrip{
    position: relative;
    bottom: -55px;
}
.arrow-carousel svg{
    width: 30px;
    height: 40px;
    opacity: 0.3;
}
