.kdetails-order{
    background-color: #f5f5f5;
    padding: 10px;
}
.kdetails-order .kdetails-card{
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
    margin-bottom: 10px;
}
.kdetails-order .kdetails-card h3{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 10px;
}
.kdetails-order .kdetails-card .mini-card{
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #ff4d0e;
    border: 2px solid #ff4d0e;
    color: #ff4d0e;
}
.kdetails-order .kdetails-card .mini-card span{
    font-size: 2em;
    font-weight: 600;
    color: black;
}

.kdetails-card .storeinfo{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.kdetails-card .storeinfo img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.kdetails-card .storeinfo p{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0;

}

.kdetails-card .payment{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.kdetails-card .payment p{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px;
}

.kdetails-card .pay{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}
.kdetails-card .pay p{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px;
}
.kdetails-card .pay span{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px;
}


.kdetails-card .proinfo{
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
}
.kdetails-card .proinfo h4{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
}
.kdetails-card .proinfo span{
    text-align: left;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 10px;
}

.kdetails-card .proinfo .products{
    display: flex;
    /* align-items: center; */
    margin-bottom: 10px;
    flex-wrap: wrap;
}
.kdetails-card .proinfo .products .product{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    
}
.kdetails-card .proinfo .products .product img{
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin-bottom: 10px;
    object-fit: contain;
}