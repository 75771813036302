.shopkeola-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.shopkeola-main .shopkeola-search{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #ebebeb;
    border: 1px solid #ebebeb;
    border-radius: 5px;
}
.shopkeola-main .shopkeola-search input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    padding: 0 20px;
}
.shopkeola-main .shopkeola-search button{
    border: none;
    outline: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
}
.shopkeola-main .shopkeola-search button:hover{
    cursor: pointer;
}
.shopkeola-main .content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    justify-items: center;
}

@media screen and (max-width: 768px) {
    .shopkeola-main .content{
        grid-template-columns: 1fr 1fr;
        grid-gap: 2px;
    }
}

