.width100{
 width: 100%!important;
}
.marginauto{
    margin: auto;
}
.justifycenter{
    display: flex;
    justify-content: center;
}
.nomarginbottom{
    margin-bottom: auto;
}