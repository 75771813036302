

.main .btn{
    border-radius: 100px!important;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: bolder;
    height: auto;
    transition: all  0.15s;
}



.main .btn:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    color:rgb(49, 118, 159);
    font-size: 14px;
    font-weight: bolder;

}

.main .btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}


.main .btn-orange{
    background-color: #ffc107;
    color: #fff;
}

.main .btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
    
}

/* .btn-orange::after {
    background-color: #ffffff;
} */

.main .btn:hover::after {
    transform: scaleY(1.7);
    opacity: 0;
}

.main .btn-animated {
    animation: moveInBottom 5s;
    animation-fill-mode: backwards;
    animation-timing-function: ease-out;
}

.bxl-whatsapp{
    font-size: 50px;
    transition: all 0.4s ease;
    cursor: pointer;
}
.bxl-whatsapp:hover{
    color: green;

}



@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@media (max-width: 995px){
    .navbar-nav > *{
        margin-top: 8px;
        margin-bottom: 0px;
    }
}
@media (max-width: 992px){
    a.btn.btn-orange{
        margin: 0px;
    }
}
