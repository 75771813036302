.flyer{
    max-width: 100%;
    position: relative;
}
.avatar-flyer{
    max-width: 30%;
    position: absolute;
    border-radius: 50%;
    top: 20%;
    left: 35%;
}
.text-flyer {
    position: absolute;
    border-radius: 50%;
    left: 30%;
    bottom: 12%;
    color: #fefefe;
    font-weight: bold;
}

.flyerSocio{
    max-width: 100%;
    position: relative;
}
.avatar-flyerSocio{
    max-width: 45%;
    position: absolute;
    border-radius: 50%;
    top: 28%;
    left: 28%;
}

@media (max-width: 280px) {
    .text-flyer {
        position: absolute;
        border-radius: 50%;
        left: 25%;
        bottom: 10%;
        color: #fefefe;
        font-weight: bold;
        font-size: 10px;
  }
}

@media (max-width:480px) {
    .avatar-flyerSocio{
        max-width: 40%;
        position: absolute;
        border-radius: 50%;
        top: 29%;
        left: 30%;
    }
  }
/* .modal-90w{
    /* width: 600px; */
    /* max-width: 600px;
  }

  @media (max-width: 769px) {
    .avatar-flyerSocio{
        max-width: 40%;
        position: absolute;
        border-radius: 50%;
        top: 29%;
        left: 30%;
    }
  }


  @media (min-width: 1024px){
    .avatar-flyerSocio{
        max-width: 35%;
        position: absolute;
        border-radius: 50%;
        top: 25%;
        left: 33%;
    }
  }


  @media (max-width: 1200px) {
    .avatar-flyerSocio{
        max-width: 40%;
        position: absolute;
        border-radius: 50%;
        top: 29%;
        left: 30%;
    }
}

@media (max-width: 1600px) {
    .avatar-flyerSocio{
        max-width: 30%;
        position: absolute;
        border-radius: 50%;
        top: 25%;
        left: 35%;
    } */
/* } */