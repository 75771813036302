.kcardinfo{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;

}
.kcardinfo .header{
    display: flex;
    justify-content: space-between;

}
.kcardinfo .header h3{
    font-size: 1.2rem;
    font-weight: 600;
}