.notifications {
    /*margin-left: auto;*/
    padding: 0 10px;
    display: flex;
}

.notification-time {
    font-size: 13px !important;
    color: #1876F2 !important;
    font-weight: bold !important;
}

.icon-notifications button {
    position: relative;
    padding: 0;
    border: none;
    background-color: #E4E6EB;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.icon-notifications_number {
    position: absolute;
    top: -8px;
    left: 30px;
}

.icon-notifications_number span {
    display: flex;
    background-color: #F02849;
    border-radius: 50%;
    font-weight: 600;
    color: wheat;
    justify-content: center;
    height: 19px;
    font-size: .8125rem;
    min-width: 19px;
}

.icon-notifications button:hover {
    background-color: #d2d2d2;
}

/*
.icon-notifications button:focus {
    background-color: #e7f3ff;
}

.icon-notifications button:focus svg {
    fill: hsl(214, 89%, 52%);
}*/

.section-notifications {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    transform: translate(-20px, 51px);
    background-color: white;
    margin-top: 5px;
    width: 360px;
    height: calc(100vh - 14vh);
    border-radius: 8px;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),
        0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    overscroll-behavior-y: contain;
}

@media screen and (max-width: 414px) {
    .section-notifications {
        border-radius: unset;
        transform: translate(0px, 55px);
        max-width: none;
        max-height: none;
        width: 100vw;
        height: calc(100vh - 56px);
        box-shadow: none;
        margin: 0;
    }
}

.notifications-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 20px 16px 12px 16px;
}

.notifications-title h2 {
    font-weight: bold;
    font-size: 24px;
}

.notifications-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 8px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

@media screen and (min-width: 768px) {

    .notifications-body::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .notifications-body::-webkit-scrollbar:vertical {
        width: 10px;
    }

    .notifications-body::-webkit-scrollbar-thumb {
        background-color: #bcc0c47c;
        border-radius: 8px;
        border: 1px solid #ffffff;
    }

    .notifications-body::-webkit-scrollbar-thumb:hover {
        background-color: #BCC0C4;
    }

    .notifications-body::-webkit-scrollbar-thumb:active {
        background-color: #BCC0C4;
    }

}

.notification:hover {
    border-radius: 8px;
    background-color: rgb(0, 0, 0, 0.05);
}

.notifications-body_push_notice {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: #fff7f7;
    border: 1px solid #e8bbbb;
    border-radius: 12px;
    gap: 8px;
    padding: 4px 6px;
}

.push_notice-icon svg {
    width: 40px;
}

.push_notice-text {
    text-align: initial;
}

.push_notice-text h3 {
    font-size: medium;
    margin-bottom: 4px;
    color: #e62b2b;
}

.push_notice-text p {
    margin: 0;
}

.push_notice-text span {
    font-size: xx-small;
    color: #737373;
}

.push_notice-button button {
    border-style: none;
    background: #ea6c6c;
    color: white;
    border-radius: 8px;
}

.push_notice-button button:disabled {
    background: #f7c6c6;
}

.notification {
    display: grid;
    grid-template-columns: 56px 1fr;
    gap: 12px;
    padding: 0 8px 2px 8px;
    align-items: center;
    border-style: none;
    background-color: unset;
}

.notification-img img {
    height: 56px;
    width: 56px;
    border: 1px solid #00000020;
    border-radius: 150px;
    filter: contrast(0.98);
}

.notification-text {
    font-family: Segoe UI Historic,
        Segoe UI,
        Helvetica,
        Arial,
        sans-serif;
    text-align: initial;
    padding: 12px 0;
}

.notification-text span {
    color: #050505;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.3333;
    word-wrap: break-word;
}

.notification-text strong {
    font-weight: 600;
}

/*
.button-action {
    background-color: #216fdb;
    color: white;
    border-radius: 20px;
    border-style: none;
    font-size: 15px;
    padding: 12px;
    font-weight: 500;
}

.button-action:disabled {
    background-color: #216fdb4f;
}*/

.no-notification {
    justify-content: center;
}

.notifications-body-no-notification {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.icon-not-notification {
    width: 50%;
    margin-left: 50px;
}

.notifications-body-no-notification span {
    font-size: 1.25rem;
    font-weight: 700;
    color: #7b7b7b;
    text-shadow: 2px 2px 2px #7b7b7b54;
}