.kcart-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}
.kcart-header .icon-back{
    font-size: 1.5rem;
    background-color: #ed9007;
    text-shadow: 0px 0px 1px #ccc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.kcart-header .icon-back svg{
    color: #fff;
    fill: #fff;
    margin-right: 3px;
    width: 100%;
    height: 100%;
}
.kcart-header .title{
    font-size: 20px;
    font-weight: 600;
}
.kcart-header .title h1{
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
@media (max-width: 768px){
    .kcart-header .title h1{
        font-size: 18px;
    }
}